import { dataKeys } from './constants';

/**
 * Adds new points to all temperatures arrays.
 * Mutates input params.
 * @param {Object} data
 * @param {Object} rawSensorData
 * @param {Object} rawSensorData.newPoint
 * @param {Object} rawSensorData.sensorsData
 */
const addTemperaturePoint = (data, rawSensorData) => {
  const {
    newPoint,
    sensorsData
  } = rawSensorData;

  const {
    timestamp
  } = newPoint || {};

  const temperatures = sensorsData
    .filter((sensor) => Number.isFinite(sensor?.currentWaterTemp))
    .reduce((result, { _id, currentWaterTemp }) => {
      result[_id] = currentWaterTemp;
      return result;
    }, {});

  if (!data?.temperatures) {
    data.temperatures = {};
    // data = {
    //   ...data,
    //   temperatures: {}
    // };
  }

  Object.entries(temperatures).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      if (data.temperatures[key]) {
        data.temperatures[key].push([timestamp, value]);
      }
    }
  });
};

/**
 * Adds new points to all devicesCurrentPower arrays.
 * Mutates input params.
 * @param {Object} data
 * @param {Object} rawSensorData
 * @param {Object} rawSensorData.newPoint
 * @param {Object} rawSensorData.sensorsData
 */
const addDevicesCurrentPowerPoint = (data, rawSensorData) => {
  const {
    newPoint,
    sensorsData
  } = rawSensorData;

  const {
    timestamp
  } = newPoint || {};

  const devicesCurrentPower = sensorsData
    .filter((sensor) => Number.isFinite(sensor?.currentPower))
    .reduce((result, { _id, currentPower }) => {
      result[_id] = currentPower;
      return result;
    }, {});

  if (!data?.devicesCurrentPower) {
    data.devicesCurrentPower = {};
    // data = {
    //   ...data,
    //   devicesCurrentPower: {}
    // };
  }

  Object.entries(devicesCurrentPower).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      if (data.devicesCurrentPower[key]) {
        data.devicesCurrentPower[key].push([timestamp, value]);
      }
    }
  });
};

/**
 * Adds new points to all invertersCurrentPower arrays.
 * Mutates input params.
 * @param {Object} data
 * @param {Object} rawSensorData
 * @param {Object} rawSensorData.newPoint
 * @param {Object} rawSensorData.sensorsData
 */
const addInvertersCurrentPowerPoint = (data, rawSensorData) => {
  const {
    newPoint,
    sensorsData
  } = rawSensorData;

  const {
    timestamp
  } = newPoint || {};

  const invertersCurrentPower = sensorsData
    .filter((sensor) => Number.isFinite(sensor?.currentPowerInvSm))
    .reduce((result, { _id, currentPowerInvSm }) => {
      result[_id] = currentPowerInvSm;
      return result;
    }, {});

  if (!data?.invertersCurrentPower) {
    data.invertersCurrentPower = {};
    // data = {
    //   ...data,
    //   invertersCurrentPower: {}
    // };
  }

  Object.entries(invertersCurrentPower).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      if (data.invertersCurrentPower[key]) {
        data.invertersCurrentPower[key].push([timestamp, value]);
      }
    }
  });
};

/**
 * Adds new points to all batteriesSoc arrays.
 * Mutates input params.
 * @param {Object} data
 * @param {Object} rawSensorData
 * @param {Object} rawSensorData.newPoint
 * @param {Object} rawSensorData.sensorsData
 */
const addBatteriesSocPoint = (data, rawSensorData) => {
  const {
    newPoint,
    sensorsData
  } = rawSensorData;

  const {
    timestamp
  } = newPoint || {};

  const batteriesSoc = sensorsData
    .filter((sensor) => Number.isFinite(sensor?.SOC))
    .reduce((result, { _id, SOC }) => {
      result[_id] = SOC;
      return result;
    }, {});

  if (!data?.batteriesSoc) {
    data.batteriesSoc = {};
    // data = {
    //   ...data,
    //   batteriesSoc: {}
    // };
  }

  Object.entries(batteriesSoc).forEach(([key, value]) => {
    if (typeof value !== 'undefined') {
      if (data.batteriesSoc[key]) {
        data.batteriesSoc[key].push([timestamp, value]);
      }
    }
  });
};

/**
 * Adds new points to all data arrays. It modifies input params.
 * Accessory function for Dashboard Epics
 * @param prevData {Object}
 * @param newPoint {Object}
 * @param additionalCurves {Object}
 */
export default function addNewPoint({ data, dataSum }, rawSensorData, additionalCurves) {
  const {
    newPoint
  } = rawSensorData;

  const {
    timestamp,
    x_consumption,
    x_production
  } = newPoint || {};

  /**
   * Temperature (currentWaterTemp)
   */
  addTemperaturePoint(data, rawSensorData);

  /**
   * Devices current power (currentPower)
   */
  addDevicesCurrentPowerPoint(data, rawSensorData);

  /**
   * Inverters current power (currentPowerInvSm)
   */
  addInvertersCurrentPowerPoint(data, rawSensorData);

  /**
   * Batteries SOC (SOC)
   */
  addBatteriesSocPoint(data, rawSensorData);

  dataKeys.forEach(({ name: key, sum = null }) => {
    let value = newPoint[key];

    switch (key) {
      // Negotiate battery discharging
      case 'battery_charging':
        if (Number.isNaN(value)) {
          value = 0;
        }
        break;
      case 'battery_discharging':
        if (Number.isNaN(value)) {
          value = 0;
        } else if (value > 0) {
          value = -value;
        }
        break;
      case 'battery':
        if (!additionalCurves?.battery) {
          value = undefined;
        }
        break;
      default:
        break;
    }

    if (typeof value !== 'undefined') {
      if (!data[key]) {
        // data = {
        //   ...data,
        //   [key]: []
        // };
        // dataSum = {
        //   ...dataSum,
        //   key: 0
        // };
        data[key] = [];
        dataSum[key] = 0;
      }
      if (sum) {
        dataSum[key] += value;
      }
      data[key].push([
        timestamp,
        value
      ]);
    }
  });

  // Notice: to avoid issue when this value can become NaN.
  if (!dataSum.self_consumption_abs) {
    // dataSum = {
    //   ...dataSum,
    //   self_consumption_abs: 0
    // };
    dataSum.self_consumption_abs = 0;
  }

  // Notice: this calculation will not be 100% correct if x_consumption or x_production is grouped.
  if (Number.isFinite(x_consumption) && Number.isFinite(x_production)) {
    dataSum.self_consumption_abs += Math.min(x_consumption, x_production);
  }
}
