import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import { Preloader } from '../../../components';
import NoAccess from './NoAccess';
import { signInCheckJWT, signInInvalidJWT, signInGoBackIfSucc } from '../../SignIn/slice';
import { getPaymentUrlRequest } from '../slice';
import { getJwtFromCookies } from '../../../api';

export default (Component, allowedRoles, _settings, ...propsNew) => {
  /**
   * Checks if user is authorized. If he is authorized, his role type has permission to visit current page and his company is allowed to redirect to external payment service it renders the page. Otherwise it does redirect him to login page.
   * @memberof module:PermissionChecker
   */
  const Authorization = (props) => {
    const {
      verifyingJWT,
      paymentUrl,
      loading,
      user
    } = props;
    const dispatch = useDispatch();

    useEffect(() => {
      if (!verifyingJWT && !loading && !user) {
        dispatch(signInGoBackIfSucc({ needGoBackAfterLogin: true }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifyingJWT, loading, user]);

    useEffect(() => {
      const jwt = getJwtFromCookies();

      if (jwt) {
        dispatch(getPaymentUrlRequest());
        if (verifyingJWT) {
          dispatch(signInCheckJWT());
        }
      } else {
        dispatch(signInInvalidJWT());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (verifyingJWT || loading) {
      return <Preloader />;
    }
    if (user) {
      if (allowedRoles.includes(user?.role?.type) && paymentUrl) {
        return (
          <Suspense fallback={<Preloader />}>
            <Component {...props} {...propsNew} />
          </Suspense>
        );
      }
      return <NoAccess />;
    }
    return <Redirect push to="/login" />;
  };

  Authorization.propTypes = {
    verifyingJWT: PropTypes.bool,
    user: PropTypes.instanceOf(Object),
    paymentUrl: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
  };

  const mapStateToProps = (state) => ({
    user: state.signIn.user,
    verifyingJWT: state.signIn.verifyingJWT,
    paymentUrl: state.payments.url,
    loading: state.payments.loading
  });

  return withTranslation()(connect(mapStateToProps)(Authorization));
};
